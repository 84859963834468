import { css } from '@emotion/react'
import { Headline } from '@dfds-ui/react-components'

import { FlexBox } from '../FlexBox'
import { Markdown } from '../Markdown'

const CardTextBlock = ({ title, titleUppercase, titleTag, text }) => {
  return (
    <FlexBox directionColumn>
      {title && (
        <Headline
          as={titleTag || 'h2'}
          css={css`
            text-transform: ${titleUppercase && 'uppercase'};
          `}
        >
          {title}
        </Headline>
      )}
      {text && <Markdown source={text} />}
    </FlexBox>
  )
}

export default CardTextBlock
